import { memo, useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import { TextAnimationFade } from "../../Utils/Animations";
import { GET_REVIEWS } from "../../Apis/home_api";
import { API_URL } from "../../Apis/config";

const ClientSection = memo(({ dataM = [] }) => {
  const [data, setData] = useState([]);
  console.log('data',data);
  
  const getData = async () => {
    const res = await GET_REVIEWS();
    if (res && res.s) {
      setData(res.r);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      bgcolor="black"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      py={["2rem", "2rem", "4rem", "4rem", "4rem"]}
      gap={["3rem", "3rem", "4rem", "5rem", "6rem"]}
    >
      <TextAnimationFade>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1.3rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: ["35px", "38px", "44px", "44px", "44px"],
              lineHeight: "64px",
              color: "white",
            }}
          >
            {dataM ? dataM[0]?.value : "What Clients Say"}
          </Typography>
          <Typography
            fontSize={["0.660rem", "0.7rem", "0.8rem", "0.8rem", "0.8rem"]}
            color="white"
            px="0.7rem"
            textAlign="center"
          >
            {dataM
              ? dataM[1]?.value
              : "Lorem ipsum dolor sit amet consectetur. Facilisi nulla est morbi aliquet eu."}
          </Typography>
        </Box>
      </TextAnimationFade>

      <Marquee
        speed={90}
        loop={0}
        autoFill={true}
        pauseOnClick={true}
        style={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        {data?.map((e, i) => (
          <Box key={i} sx={{ mr: "1.6rem", pt: "5rem", cursor: "grab" }}>
            <Client
              photo={API_URL.baseUrl + API_URL.getReviewPhoto + e.photo_thumb}
              name={e.first_name + " " + e.last_name}
              review={e.review}
            />
          </Box>
        ))}
      </Marquee>
    </Box>
  );
});

export default ClientSection;

function Client({ name, photo, review }) {
  return (
    <Box
      bgcolor="#F5FEFD"
      minHeight={["20rem", "22rem", "24rem", "24rem", "24rem"]}
      width={["20rem", "21rem", "26rem", "26rem", "26rem"]}
      borderRadius="25px"
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="start"
      px="1rem"
      pb="1rem"
      gap="1.1rem"
    >
      <Avatar
        src={photo}
        sx={{
          width: ["5.5rem", "5.5rem", "7rem", "7rem", "7rem"],
          height: ["5.5rem", "5.5rem", "7rem", "7rem", "7rem"],
          objectFit: "fill",
          position: "absolute",
          top: "-3.6rem",
          left: ["7.5rem", "7.6rem", "9.9rem", "9.9rem", "9.9rem"],
        }}
        alt=""
      />

      <Typography
        fontSize={["1.3", "1.3rem", "1.3rem", "1.3rem", "1.3rem"]}
        mt="3.7rem"
      >
        {name}
      </Typography>
      <Avatar
        src={require("../../Assets/client_icon.png")}
        sx={{
          width: ["2rem", "2rem", "3.8rem", "3.8rem", "3.8rem"],
          height: "auto",
          objectFit: "fill",
        }}
        alt=""
      />
      <Typography
        variant="little"
        overflow="auto"
        textAlign="start"
        fontSize={["14px", "14px", "16px", "16px", "16px"]}
      >
        {review}
      </Typography>
    </Box>
  );
}
